<template>
  <master-detail
    :cols="cols"
    :opts="opts"
    :customResource="customResource"
    :has-new-button="false"
    :can-edit="false"
    :can-delete="false"
    descriptionProperty="id"
  ></master-detail>
</template>

<script>
import { mapGetters } from 'vuex';
import { DedicacoesEnum, DedicacoesLabels } from '@/core/enums/dedicacoes';

export default {
  components: {
    'master-detail': () => import('@/components/master-detail.vue'),
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols: function () {
      return [
        {
          key: "funcionarioId",
          name: "ID",
          hideInform: true,
        },
        {
          key: "matricula",
          name: "Matrícula",
        },
        {
          key: "nome",
          name: "Colaborador",
          type: this.$fieldTypes.AUTOCOMPLETE,
        },
        {
          key: "dedicacao",
          name: "Dedicação",
          type: this.$fieldTypes.SELECT,
          rel: { to: "dedicacoes", key: "dedicacao", name: "name" },
        },
        {
          key: "competenciaDe",
          name: "Competência de",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "competenciaAte",
          name: "Competência até",
          type: this.$fieldTypes.TEXT,
        },
      ];
    },
    customResource: function () {
      const resource = this.apiResource(`/v1/rh/dedicacao/cliente/${this.clientId}`);
      return {
        ...resource,
        get: (param) => {
          return resource
            .get(param)
            .then((result) => {
              const parsedData = result.map((dedicacao) => ({
                ...dedicacao,
                competenciaAte: dedicacao.competenciaAte == 0 ? 'Fim indefinido' : this.$options.filters.toMonth(dedicacao.competenciaAte),
                competenciaDe: dedicacao.competenciaDe == 0 ? 'Inicio indefinido' : this.$options.filters.toMonth(dedicacao.competenciaDe),
              }));
              return parsedData;
            });
        },
      };
    },
  },
  data: function () {
    return {
      opts: {
        dedicacoes: [
          {
            dedicacao: DedicacoesEnum.NENHUMA,
            name: DedicacoesLabels.NENHUMA,
          },
          {
            dedicacao: DedicacoesEnum.EXCLUSIVA,
            name: DedicacoesLabels.EXCLUSIVA,
          },
          {
            dedicacao: DedicacoesEnum.PARCIAL,
            name: DedicacoesLabels.PARCIAL,
          },
        ],
      },
    };
  },
};
</script>
