export const DedicacoesEnum = {
  "NENHUMA": 0,
  "EXCLUSIVA": 1,
  "PARCIAL": 2,
};

export const DedicacoesLabels = {
  [DedicacoesEnum.NENHUMA]: "Nenhuma",
  [DedicacoesEnum.EXCLUSIVA]: "Exclusiva",
  [DedicacoesEnum.PARCIAL]: "Parcial",
  "NENHUMA": "Nenhuma",
  "EXCLUSIVA": "Exclusiva",
  "PARCIAL": "Parcial",
};
